const BoConfig = {
sidebar: [
    {name:"Dashboard",link:"BoDashboard",icon:"fas fa-tachometer-alt",},
    {name:"Blog",link:"AppPostBlog",icon:"fas fa-newspaper",},
    {name:"Setting SEO",link:"BoStaticSeo",icon:"fas fa-cube",},
    {name:"User Management", title:"UserManagement",icon:"fas fa-users",childs:[
        {name:"Users",link:"BoUsers"},
        {name:"User Level",link:"BoUserLevel"},
    ]},
],
}
export default BoConfig